import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/router';
import { Card } from 'react-bootstrap';
import { t } from 'react-switch-lang';
import { faWarning } from '@awesome.me/kit-3f20e419a7/icons/classic/solid';
import Button from './Button';
import styles from '../styles/ErrorPages.module.scss';
import { events } from '../utils/Amplitude';
import { ExternalLink } from './Link';
import RenderMarkdown from './RenderMarkdown';

/**
 * Renders a card with icon, title, message, and a button to return to the home page
 * @param {object} props
 * @param {string} [props.title] The title of the error message. Default is 'Error_Default_Title'
 * @param {string} [props.message] The error message to be displayed. Hidden if not provided
 * @param {object} [props.icon] The icon to display at the top. Default is faWarning
 * @param {boolean} [props.hideHelpCentreLink] Whether to hide help centre link. Default is false
 */
export default function ErrorDisplay({
  title,
  message,
  icon = faWarning,
  hideHelpCentreLink = false,
}) {
  const router = useRouter();
  return (
    <Card>
      <Card.Body className={styles.errorCard}>
        <FontAwesomeIcon icon={icon} size="3x" />
        <h1 className={styles.errorTitle}>
          <RenderMarkdown
            markdown={title || t('Error_Default_Title')}
          />
        </h1>
        {message && <p>{message}</p>}
        {!hideHelpCentreLink && (
          <div className="mb-4">
            <ExternalLink
              text={t('ErrorPage_TransactionHelpLink')}
              href={t('Link_PaySimplyHelp')}
              aria-label={t('PaySimplyHelpCentre_LinkDescription')}
              ampEvent={events.USER_CLICKED_HELP_CENTRE_LINK_ERROR_PAGE}
            />
          </div>
        )}

        <Button
          onClick={() => router.push('/')}
          text={t('ErrorPage_ReturnHome_ButtonText')}
          ampEvent={events.USER_CLICKED_RETURN_HOME_ERROR_PAGE}
        />
      </Card.Body>
    </Card>
  );
}

export function toErrorPage(title, message) {
  const query = {};
  if (title) query.title = title;
  if (message) query.message = message;
  const search = new URLSearchParams(query).toString();
  if (search) return `/error?${search}`;

  return '/error';
}
