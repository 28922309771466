import { Col, Container, Row } from 'react-bootstrap';
import { faMemoCircleInfo } from '@awesome.me/kit-3f20e419a7/icons/classic/solid';
import { t } from 'react-switch-lang';
import { useEffect } from 'react';
import MainLayout from '../components/MainLayout';
import styles from '../styles/ErrorPages.module.scss';
import ErrorDisplay from '../components/ErrorDisplay';
import { events, logAmpEvent } from '../utils/Amplitude';

export default function Custom404() {
  useEffect(() => {
    logAmpEvent(events.USER_VIEWED_404_PAGE);
  }, []);

  return (
    <MainLayout title={t('PageTitle_404_ErrorPage')}>
      <Container fluid="xxl" className={styles.errorPageContainer}>
        <Row className="di-flex justify-content-center">
          <Col lg={9} xs={12} className="mt-5">
            <ErrorDisplay
              title={t('PageTitle_404_ErrorPage')}
              message={t('ErrorPage_404_ErrorMessage')}
              icon={faMemoCircleInfo}
              hideHelpCentreLink
            />
          </Col>
        </Row>
      </Container>
    </MainLayout>
  );
}
